import s from "./History.module.css";

const HistoryBlock = ({ lang }) => {
  return (
    <div className={s.history}>
      <div className={s.info}>
        {lang === "ru"
          ? "Медиаплатформа БРИКС создана в год председательства России в БРИКС Центром международного сотрудничества при Министерстве Просвещения России и Центром защиты прав и интересов детей."
          : 'The BRICS media platform was created in the year of Russia`s BRICS presidency by the Center for International Cooperation under the Ministry of Education of Russia and Federal center protection childrens rights'}
      </div>
      <div className={s.partners}>
        <div className={s.count}>
          <span className={s.num}>20</span>
          <span className={s.word}>
            {lang === "ru" ? "ОРГАНИЗАЦИЙ" : "ORGANIZATIONS"}
          </span>
        </div>
        <div className={s.description}>
          {lang === "ru"
            ? "Партнерами проекта уже стали более 20 правительственных и неправительственных организаций из стран БРИКС."
            : "More than 20 governmental and non-governmental organizations from the BRICS countries have already become partners of the project."}
        </div>
      </div>
    </div>
  );
};
export const MiniHistoryBlock = ({ lang }) => {
  return (
    <div
      className={s.history}
      style={{ border: "none", marginTop: "0", paddingTop: "0" }}
    >
      <div className={s.partners}>
        <div className={s.count}>
          <span className={s.num}>20</span>
          <span className={s.word}>
            {lang === "ru" ? "ОРГАНИЗАЦИЙ" : "ORGANIZATIONS"}
          </span>
        </div>
        <div className={s.description}>
          {lang === "ru"
            ? "Партнерами проекта уже стали более 20 правительственных и неправительственных организаций из стран БРИКС."
            : "More than 20 governmental and non-governmental organizations from the BRICS countries have already become partners of the project."}
        </div>
      </div>
    </div>
  );
};

export default HistoryBlock;

import s from "../LoginModal/Login.module.css"
import {useState} from "react";
import {Button, ConfigProvider, Form, Input, Select} from "antd";
import {HeaderThemes} from "../../themes/HeaderThemes";
import {useSelector} from "react-redux";

const {Option} = Select;

const IdeaModal = ({setIsModalOpen, onCancel}) => {

    return (
        <div className={s.modal}>
            <AskForm setIsModalOpen={setIsModalOpen}/>
        </div>
    )
}
export default IdeaModal

const AskForm = ({setIsModalOpen}) => {
    const {lang} = useSelector(state => state.lang);
    const {isAuthenticated} = useSelector(state => state.auth);
    const [category, setCategory] = useState("");
    const [error, setError] = useState("")

    const handleCategoryChange = (value) => {
        setCategory(value);
    };

    let formdata = new FormData();

    return (
        <ConfigProvider theme={HeaderThemes.loginForm}>
            <Form
                className={s.form}
                name="askForm"
                layout="vertical"
                onFinish={async (values) => {
                    formdata.append("name", values.name);
                    formdata.append(
                        category === "предложить идею" ? "mind" : "about",
                        values[category === "предложить идею" ? "mind" : "about"]
                    );
                    formdata.append("email", values.email);

                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem("access")}`,
                        },
                        body: formdata,
                        redirect: "follow",
                        withCredentials: true,
                    };

                    if (isAuthenticated) {
                        try {
                            const response = await fetch(
                                "https://bricsyoung.com/api/zayavka",
                                requestOptions
                            );
                            const data = await response.json();
                            if (data.id) {
                                setError("")
                                setIsModalOpen(false)
                            } else if (data.detail) {
                                setError(data.detail);
                            }
                        } catch (error) {
                            // Обработка ошибок
                        }
                    }
                }}
                autoComplete="off"
                requiredMark={false}
            >
                <Form.Item
                    name="category"
                    label={lang === "ru" ? "Категория" : "Category"}
                    rules={[
                        {
                            required: true,
                            message:
                                lang === "ru"
                                    ? "Пожалуйста, выберите категорию!"
                                    : "Please select the category!",
                        },
                    ]}
                >
                    <Select
                        placeholder={
                            lang === "ru"
                                ? "Пожалуйста, выберите категорию"
                                : "Please select the category"
                        }
                        onChange={handleCategoryChange}
                    >
                        <Option value="стать редактором">
                            {lang === "ru" ? "Стать редактором" : "Become an editor"}
                        </Option>
                        <Option value="предложить идею">
                            {lang === "ru" ? "Предложить идею" : "Come up with an idea"}
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label={lang === 'ru' ? "Имя" : "Name"}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: lang === 'ru' ? "Напишите имя!" : 'Write Your Name!',
                        },
                    ]}
                >
                    <Input.TextArea showCount maxLength={400}/>
                </Form.Item>
                <Form.Item
                    label={
                        lang === "ru"
                            ? category === "предложить идею"
                                ? "Идея"
                                : "О себе"
                            : category === "предложить идею"
                                ? "Idea"
                                : "About"
                    }
                    name={category === "предложить идею" ? "mind" : "about"}
                    rules={[
                        {
                            required: true,
                            message:
                                lang === "ru"
                                    ? category === "предложить идею"
                                        ? "Опишите свою идею!"
                                        : "Напишите о себе!"
                                    : category === "предложить идею"
                                        ? "Describe your idea!"
                                        : "Write about yourself!",
                        },
                    ]}
                >
                    <Input.TextArea showCount maxLength={400}/>
                </Form.Item>

                <Form.Item
                    name="email"
                    label={lang === "ru" ? "E-mail" : "E-mail"}
                    rules={[
                        {
                            type: "email",
                            message:
                                lang === "ru"
                                    ? "Введенный адрес электронной почты неверен!"
                                    : "The input is not valid E-mail!",
                        },
                        {
                            required: true,
                            message:
                                lang === "ru"
                                    ? "Введите свой E-mail!"
                                    : "Please input your E-mail!",
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item>
                    <div style={{marginTop: "30px"}} className={s.nav}>
                        <Button htmlType="submit">
                            {lang === "ru" ? "Отправить" : "Send"}
                        </Button>
                        {error && <div className={s.error}>{error}</div>}
                    </div>
                </Form.Item>
            </Form>
        </ConfigProvider>
    )
}

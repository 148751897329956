import {Card, Col, Row} from "antd";
import React from "react";
import {NewsCard} from "../Main/NewsBlock";


export const AnotherNewsDisplay = ({
                                response,
                                load = true,
                                url = "/news/",
                            }) => {
    return (
        <Row gutter={[32, 32]}>
            {load
                ? response.map((el) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} key={el.id}>
                        <NewsCard {...el} url={`${url + el.id}`}/>
                    </Col>
                ))
                : Array.from({length: 8}).map((_, index) => (
                    <Col span={12} key={index}>
                        <Card loading={true}/>
                    </Col>
                ))}
        </Row>
    );
};

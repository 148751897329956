import s from "./Footer.module.css"
import {NavLink} from "react-router-dom";
import logo from "../../assets/Header/logo+.svg"
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {Button, ConfigProvider, Modal} from "antd";
import React, {useState} from "react";
import logo1 from "../../assets/Header/LoginLogo.svg";
import IdeaModal from "./IdeaModal";
import {FooterThemes} from "../../themes/FooterThemes";

const Footer = () => {
    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    const isDesktop = useMediaQuery({query: '(min-width: 769px)'})
    return (
        <>
            {isDesktop && <BigFooter/>}
            {isMobile && <SmallFooter/>}
        </>
    )
}
export default Footer

// const InfoBlock = ({lang, title, enTitle, email, url}) => {
//     return (
//         <div className={s.infoBlock}>
//             <h4>
//                 {lang === "ru" ? title : enTitle}
//             </h4>
//             <div>
//                 <a target="_blank" href={`mailto:${email}`}>
//                     E-mail: {email}
//                 </a>
//             </div>
//             <div>
//                 <a target="_blank" href={`https://vk.com/${url}`}>
//                     <img src={vk} alt=""/>vk.com/{url}
//                 </a>
//             </div>
//
//             <div>
//                 <a target="_blank" href={`https://t.me/${url}`}>
//                     <img src={tg} alt=""/>t.me/{url}
//                 </a>
//             </div>
//         </div>
//     )
// }
const InfoBlock = ({lang}) => {
    return (
        <div className={s.infoBlock}>
            <h4>
                {lang === "ru" ? 'E-mail для связи' : "E-mail for communication"}
            </h4>
            <div>
                <a target="_blank" rel="noreferrer" href={`mailto:support@bricsyoung.com`}>
                    {lang === "ru" ? 'Техподдержка' : "Technical Support"}: support@bricsyoung.com
                </a>
            </div>
            <div>
                <a target="_blank" rel="noreferrer" href={`mailto:sport@bricsyoung.com`}>
                    {lang === "ru" ? 'Спорт' : "Sport"}: sport@bricsyoung.com
                </a>
            </div>
            <div>
                <a target="_blank" rel="noreferrer" href={`mailto:science@bricsyoung.com`}>
                    {lang === "ru" ? 'Наука' : "Science"}: science@bricsyoung.com
                </a>
            </div>
        </div>
    )
}

const IdeaButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { lang } = useSelector((state) => state.lang);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const onCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <ConfigProvider theme={FooterThemes.ask}>
            <Button className={s.ask} type="text" onClick={showModal}>
                <span>{lang === "ru" ? "Задать вопрос" : "Ask A Question"}</span>
            </Button>
            <Modal
                footer={() => <></>}
                title={<img src={logo1} alt="" />}
                open={isModalOpen}
                onCancel={onCancel}
            >
                <IdeaModal onCancel={onCancel} setIsModalOpen={setIsModalOpen} />
            </Modal>
        </ConfigProvider>

    )
}

const YouTubeButton = ({lang}) => {
    return (
        <a className={s.yt} href="https://www.youtube.com/@BRICSMedia-j1p">
            <div className={s.ytIcon}/>

            <div className={s.ytText}>
                {lang === "ru" ? 'Смотрите трансляции мероприятий на нашем ютуб-канале' : 'Watch live events on our YouTube channel'}

            </div>
        </a>
    )
}
const SiteTitle = ({lang}) => {
    return (<div className={s.sideTitle}>
            <p>
                <a target="_blank" rel="noreferrer"
                   href={lang === "ru" ? "https://bricsyoung.com/media/media/behavior/2_Политика_перс._данных_БРИКС_1.pdf" : "https://bricsyoung.com/media/media/behavior/Personal_Data_Processing_Policy.pdf"}>

                    {lang === "ru" ? 'Политика обработки персональных данных' : 'Personal Data Processing Policy'}
                </a>
            </p>
            <div>
                {lang === 'ru' ? 'Коммуникационная молодежная площадка "Медиаплатформа БРИКС" Ⓒ 2024' : "BRICS Media Platform Communication Youth Platform Ⓒ 2024"}
            </div>
        </div>
    )
}
const BigFooter = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <footer className={s.footer}>
            <div className={s.info}>


                <div className={s.upper}>
                    <div className={s.logo}>
                        <NavLink to="/">
                            <img src={logo} alt=""/>
                        </NavLink>
                    </div>
                    <div className={s.nav}>
                        <NavLink to="/">
                            {lang === "ru" ? 'Главная' : 'Main'}
                        </NavLink>
                        <NavLink to="/news">
                            {lang === "ru" ? 'Новости' : 'News'}
                        </NavLink>
                        <NavLink to="/announcements">
                            {lang === "ru" ? 'Анонсы' : 'Announcements'}
                        </NavLink>
                        <NavLink to="/about">
                            {lang === "ru" ? 'О проекте' : 'About'}
                        </NavLink>
                    </div>

                    {/*<div className={s.social}>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={yt} alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={fb} alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={inst} alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <a href="#">*/}
                    {/*        <img src={twit} alt=""/>*/}
                    {/*    </a>*/}
                    {/*    <a href="https://t.me/youthbrics">*/}
                    {/*        <img src={tg} alt=""/>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
                {/*<div className={s.bottom}>*/}
                {/*    <NavLink to="#">*/}
                {/*        {lang === "ru" ? 'Политика конфиденциальности' : 'Privacy Policy'}*/}
                {/*    </NavLink>*/}
                {/*    <span>*/}
                {/*        {lang === "ru" ? 'Ⓒ 2024 БРИКС Медиапортал' : 'Ⓒ 2024 BRICS Media Platform'}*/}
                {/*    </span>*/}
                {/*</div>*/}
                <div className={s.bottom}>
                    <div className={s.infoLeft}>
                        <InfoBlock lang={lang}
                                   title="Центр международного сотрудничества Министерства просвещения Российской Федерации"
                                   enTitle="The Center for International Cooperation of the Ministry of Education of the Russian Federation"
                                   email="info@cic-edu.ru"
                                   url="cms_project"/>
                        <IdeaButton/>
                    </div>

                    <YouTubeButton lang={lang}/>
                </div>
                <SiteTitle lang={lang}/>
            </div>
        </footer>
    )
}

const SmallFooter = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <footer className={s.footer}>
            <div className={s.info}>
                <div className={s.upper}>
                    <div className={s.logo}>
                        <NavLink to="/">
                            <img src={logo} alt=""/>
                        </NavLink>
                    </div>
                </div>
                <YouTubeButton lang={lang}/>
                <InfoBlock lang={lang}
                           title="Центр международного сотрудничества Министерства просвещения Российской Федерации"
                           enTitle="Center for International Cooperation under the Ministry of Education of Russia"
                           email="info@cic-edu.ru"
                           url="cms_project"/>
                <InfoBlock lang={lang}
                           title="Проектный офис международного молодёжного сотрудничества “Россия – БРИКС”"
                           enTitle="Project office for international youth cooperation “Russia – BRICS”"
                           email="youthbrics73@yandex.ru"
                           url="youthbrics"/>
                <SiteTitle/>
            </div>
        </footer>
    )
}
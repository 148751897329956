import s from "../../News/SingleNewsPage/NewsLayout.module.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import {Carousel} from "antd";
import {NewCustomNextArrow, NewCustomPrevArrow} from "../../../Components/Main/NewsCarousel";

const BusinessSinglePage = () => {
    const { id } = useParams();
    const [loading2, setLoading2] = useState(true);
    const [news, setNews] = useState({
        company: "loading...",
        companyen: "loading...",
        description: "loading...",
        descriptionen: "loading...",
        vacancy: "loading...",
        vacancyen: "loading...",
        country: "loading...",
        countryen: "loading...",
        contacts: "loading...",
        contactsen: "loading...",
        university_ref: "loading...",
        images: [],
    });
    const { lang } = useSelector((state) => state.lang);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await fetch(
                //     `https://bricsyoung.com/api/busyness?id=${id}`
                // );
                const response = await fetch(
                    `https://bricsyoung.com/api/busyness/`
                );
                const data = await response.json();
                setNews(data[0]);
            } catch (e) {
            } finally {
                setLoading2(false);
            }
        };
        fetchData();
    }, [id]);

    if  (loading2) {
        return <div>Loading...</div>;
    }

    return (
        <div className={s.root}>
            <div className={s.layout}>
                <div className={s.main}>
                    <article className={s.articleDetailBlock}>
                        <div className={s.title}>
                            <h1>{lang === "ru" ? `${news.vacancy} (${news.country})` : `${news.vacancyen} (${news.countryen})`}</h1>
                        </div>
                        <ImageCarousel images={news.images} />

                        <div className={s.text}>
                            <b>О вакансии:</b><br/>
                            {lang === "ru" ? parse(news?.description) : parse(news?.descriptionen)}
                        </div>
                        <div className={s.text}>
                            <b>Контакты:</b><br/>
                            {lang === "ru" ? parse(news?.contacts) : parse(news?.contactsen)}
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default BusinessSinglePage;

const ImageCarousel = ({images}) => {
    return (
        <Carousel autoplay arrows
                  prevArrow={ <NewCustomPrevArrow/> }
                  nextArrow={<NewCustomNextArrow/>}>
            {images.map((src, index) => (
                <div key={index} className={s.carouselSlide}>
                    <img src={"https://bricsyoung.com" + src.image} alt={`Slide ${index + 1}`} className={s.carouselImage} />
                </div>
            ))}
        </Carousel>
    );
};
import {Card, Carousel, Col, ConfigProvider, Row} from "antd";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {MainThemes} from "../../../themes/MainThemes";
import s from "../../Main/NewsBlock/News.module.css";
import React from "react";
import {NewCustomNextArrow, NewCustomPrevArrow} from "../../Main/NewsCarousel";

export const BusinessBlock = ({
                                  response,
                                  load = true,
                                  url = "/vacancy/",
                              }) => {
    return (
        <Row gutter={[32, 32]}>
            {load
                ? response.map((el) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} key={el.id}>
                        <BusinessCard {...el} url={`${url + el.id}`}/>
                    </Col>
                ))
                : Array.from({length: 8}).map((_, index) => (
                    <Col span={6} key={index}>
                        <Card loading={true}/>
                    </Col>
                ))}
        </Row>
    );
};

export const BusinessCard = ({
                                 company,
                                 companyen,
                                 images,
                                 url,
                                 vacancy,
                                 vacancyen,
                                 country,
                                 countryen,
                             }) => {
    const {lang} = useSelector((state) => state.lang);

    return (

        <ConfigProvider theme={MainThemes.news}>
            <Card
                className={s.card}
                style={{
                    width: "calc(100%)",

                }}
                cover={
                    <Carousel autoplay style={{
                        width: "calc(100% - 2px)", borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        overflow: "hidden",
                        marginLeft: "1px"// Карточка заполняет ширину родителя
                    }} arrows
                              prevArrow={ <NewCustomPrevArrow/> }
                              nextArrow={<NewCustomNextArrow/>}>
                        {images.map((img, index) => (
                            <div
                                key={index}
                                style={{
                                    width: "calc(100%)", // Контейнер заполняет всю ширину

                                    height: "300px", // Фиксированная высота слайдера
                                    position: "relative",
                                    background: "#fff", // Белый фон для вертикальных изображений
                                    overflow: "hidden", // Скрытие лишних частей изображения
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderTopLeftRadius: "20px",
                                    borderTopRightRadius: "20px"
                                }}
                            >
                                <img
                                    src={"https://bricsyoung.com" + img.image}
                                    style={{
                                        width: "100%",
                                        height: "300px",
                                        objectFit: "cover", // Универсальная обработка изображений
                                        objectPosition: "center",
                                        marginTop: "1px",
                                        borderTopLeftRadius: "20px",
                                        borderTopRightRadius: "20px"
                                    }}
                                    alt={`Slide ${index + 1}`}
                                />
                            </div>
                        ))}
                    </Carousel>
                }
            >
                <NavLink to={url}>
                    <div className={s.business}>
                        <h3 className={s.vacancy}>
                            {lang === "ru" ? `${vacancy} (${country})` : `${vacancyen} (${countryen})`}
                        </h3>
                        <p>{lang === "ru" ? company : companyen}</p>
                    </div>
                </NavLink>
            </Card>
        </ConfigProvider>

    );
};


import React, {useEffect, useRef, useState} from 'react';
import {Button, Carousel, ConfigProvider} from 'antd';
import s from "./Carousel.module.css"
import {useSelector} from "react-redux";
import {MainThemes} from "../../../themes/MainThemes";
import {NavLink} from "react-router-dom";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {formatDate} from "../NewsBlock";

const NewsCarousel = () => {
    const ref = useRef()

    const [respData, setData] = useState(
        [
            {
                id: 1,
                date: "2000-01-01",
                header: "loading...",
                img: "",
                body: "loading...",
                bodyen: "loading...",
                headeren: "loading...",
            },
            {
                id: 2,
                date: "2000-01-01",
                header: "loading...",
                img: "",
                body: "loading...",
                bodyen: "loading...",
                headeren: "loading...",
            },
            {
                id: 3,
                date: "2000-01-01",
                header: "loading...",
                img: "",
                body: "loading...",
                bodyen: "loading...",
                headeren: "loading...",
            },
        ]
    )
    const {lang} = useSelector(state => state.lang);
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})
    useEffect(() => {
        const fetchData = async () => {
            await axios.get("https://bricsyoung.com/api/banner")
                .then((res) => {
                    setData(res.data)
                })
                .catch((e) => {
                })

        }
        fetchData()
    }, []);

    const response = Object.values(respData).map(function (item) {
            return {
                body: item.body,
                bodyen: item.bodyen,
                date: item.date || '2000-01-01',
                header: item.header,
                headeren: item.headeren,
                id: item.id,
                img: 'https://bricsyoung.com' + item.img
            }
        }
    ).slice(0, 4)
    return (
        <Carousel
            arrows
            prevArrow={!isSmall ? <CustomPrevArrow/> : <></>}
            nextArrow={!isSmall ? <CustomNextArrow/> : <></>}
            speed={1500}
            autoplay={true}
            dots={false}
            style={{width: "calc(90% * 0.95 - 2px)", margin: "30px auto 0px auto", maxWidth: "1640px"}} ref={ref}
            draggable={true}>
            {/*<Carousel arrows style={{width: "calc(90% * 0.95)", margin: "30px auto 0px auto", maxWidth: "1640px"}} dots={{className:`${s.dots}`}} dotPosition={"bottom"} ref={ref} draggable={true}>*/}
            <div>
                <div className={s.slide}>
                    <div className={s.text}>
                        <div>
                            <div className={s.date}>{formatDate(response[0].date, lang)}</div>
                            <h2>
                                {lang === "ru" ? response[0].header : response[0].headeren}
                            </h2>
                        </div>
                        <div>
                            {/*<ConfigProvider theme={MainThemes.slider}>*/}
                            {/*    <Button shape="circle" onClick={() => {*/}
                            {/*        ref.current.prev()*/}
                            {/*    }} icon={<LeftOutlined/>}/>*/}
                            {/*    <span style={{paddingRight: "6px", paddingLeft: "6px"}}><strong>1</strong> / 3</span>*/}
                            {/*    <Button shape="circle" onClick={() => {*/}
                            {/*        ref.current.next()*/}
                            {/*    }} icon={<RightOutlined/>}/>*/}
                            {/*</ConfigProvider>*/}
                            <ConfigProvider theme={MainThemes.newSlider}>
                                <NavLink to={`/banner/${response[0].id}`}>
                                    <Button>
                                        {lang === "ru" ? 'Подробнее' : 'In Details'}
                                    </Button>

                                </NavLink>
                            </ConfigProvider>

                        </div>
                    </div>
                    <div className={s.image}>
                        <img src={response[0].img} alt="BRICS"/>
                    </div>
                </div>
            </div>
            <div>
                <div className={s.slide}>
                    <div className={s.text}>
                        <div>
                            <div className={s.date}>{formatDate(response[1].date, lang)}</div>
                            <h2>
                                {lang === "ru" ? response[1].header : response[1].headeren}
                            </h2>
                        </div>
                        <div>
                            {/*<ConfigProvider theme={MainThemes.slider}>*/}
                            {/*    <Button shape="circle" onClick={() => {*/}
                            {/*        ref.current.prev()*/}
                            {/*    }} icon={<LeftOutlined/>}/>*/}
                            {/*    <span style={{paddingRight: "6px", paddingLeft: "6px"}}><strong>1</strong> / 3</span>*/}
                            {/*    <Button shape="circle" onClick={() => {*/}
                            {/*        ref.current.next()*/}
                            {/*    }} icon={<RightOutlined/>}/>*/}
                            {/*</ConfigProvider>*/}
                            <ConfigProvider theme={MainThemes.newSlider}>
                                <NavLink to={`/banner/${response[1].id}`}>
                                    <Button>
                                        {lang === "ru" ? 'Подробнее' : 'In Details'}
                                    </Button>

                                </NavLink>
                            </ConfigProvider>

                        </div>
                    </div>
                    <div className={s.image}>
                        <img src={response[1].img} alt="BRICS"/>
                    </div>
                </div>
            </div>
            <div>
                <div className={s.slide}>
                    <div className={s.text}>
                        <div>
                            <div className={s.date}>{formatDate(response[2].date, lang)}</div>
                            <h2>
                                {lang === "ru" ? response[2].header : response[2].headeren}
                            </h2>
                        </div>
                        <div>
                            {/*<ConfigProvider theme={MainThemes.slider}>*/}
                            {/*    <Button shape="circle" onClick={() => {*/}
                            {/*        ref.current.prev()*/}
                            {/*    }} icon={<LeftOutlined/>}/>*/}
                            {/*    <span style={{paddingRight: "6px", paddingLeft: "6px"}}><strong>1</strong> / 3</span>*/}
                            {/*    <Button shape="circle" onClick={() => {*/}
                            {/*        ref.current.next()*/}
                            {/*    }} icon={<RightOutlined/>}/>*/}
                            {/*</ConfigProvider>*/}
                            <ConfigProvider theme={MainThemes.newSlider}>
                                <NavLink to={`/banner/${response[2].id}`}>
                                    <Button>
                                        {lang === "ru" ? 'Подробнее' : 'In Details'}
                                    </Button>

                                </NavLink>
                            </ConfigProvider>

                        </div>
                    </div>
                    <div className={s.image}>
                        <img src={response[2].img} alt="BRICS"/>
                    </div>
                </div>
            </div>
            <div>
                <div className={s.slide}>
                    <div className={s.text}>
                        <div>
                            <div className={s.date}>{formatDate(response[2]?.date, lang)}</div>
                            <h2>
                                {lang === "ru" ? response[3]?.header : response[3]?.headeren}
                            </h2>
                        </div>
                        <div>
                            {/*<ConfigProvider theme={MainThemes.slider}>*/}
                            {/*    <Button shape="circle" onClick={() => {*/}
                            {/*        ref.current.prev()*/}
                            {/*    }} icon={<LeftOutlined/>}/>*/}
                            {/*    <span style={{paddingRight: "6px", paddingLeft: "6px"}}><strong>1</strong> / 3</span>*/}
                            {/*    <Button shape="circle" onClick={() => {*/}
                            {/*        ref.current.next()*/}
                            {/*    }} icon={<RightOutlined/>}/>*/}
                            {/*</ConfigProvider>*/}
                            <ConfigProvider theme={MainThemes.newSlider}>
                                <NavLink to={`/banner/${response[3]?.id}`}>
                                    <Button>
                                        {lang === "ru" ? 'Подробнее' : 'In Details'}
                                    </Button>

                                </NavLink>
                            </ConfigProvider>

                        </div>
                    </div>
                    <div className={s.image}>
                        <img src={response[3]?.img} alt="BRICS"/>
                    </div>
                </div>
            </div>

            {/* Другие слайды здесь */}
        </Carousel>
    );
};

const CustomPrevArrow = (props) => {
    const {onClick} = props;
    return (
        <div
            className={`${s.arrow} ${s.prev}`}
            onClick={onClick}
        >
            <LeftOutlined/>
        </div>
    );
};

const CustomNextArrow = (props) => {
    const {onClick} = props;
    return (
        <div
            className={`${s.arrow} ${s.next}`}
            onClick={onClick}
        >
            <RightOutlined/>
        </div>
    );
};

export const NewCustomPrevArrow = (props) => {
    const {onClick} = props;
    return (
        <div
            className={`${s.arrow1} ${s.prev1}`}
            onClick={onClick}
        >
            <LeftOutlined/>
        </div>
    );
};

export const NewCustomNextArrow = (props) => {
    const {onClick} = props;
    return (
        <div
            className={`${s.arrow1} ${s.next1}`}
            onClick={onClick}
        >
            <RightOutlined/>
        </div>
    );
};

export default NewsCarousel;
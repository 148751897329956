import {ConfigProvider, Menu} from "antd";
import {MainThemes} from "../../themes/MainThemes";
import styles from "./AnotherNewsLayout.module.css"
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {NavLink, useLocation} from "react-router-dom";

export const AnotherNewsLayout = ({children, title}) =>{
    const location = useLocation();
    const {lang} = useSelector(state => state.lang);
    const getSelectedKey = (pathname) => {
        return pathname;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const items = [
        {
            key: "/exp",
            label: <NavLink to="/exp">{lang === 'ru' ? "Новости" : "News"}</NavLink>,
        },

    ]

    return (
        <ConfigProvider theme={MainThemes.science}>
            <div className={styles.science}>
                <h1>
                    {title}
                </h1>
                <div className={styles.info}>
                    <div className={styles.left}>
                        {children}
                    </div>
                    <div className={styles.right}>
                        <div className={styles.menuWrapper}>
                            <Menu mode="inline" style={{ justifyContent: "left", borderRight: "none" }}
                                  selectedKeys={[getSelectedKey(location.pathname)]} items={items} />
                        </div>
                        <style jsx>{`
                            .ant-menu-item-selected {
                                background-color: white !important;
                            }
                            .ant-menu-sub  {
                                background-color: white !important;
                            }
                            .ant-menu-submenu-title a {
                                color: #4d4d4d;
                            }
                        `}</style>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
}
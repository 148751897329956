export const FooterThemes = {
    ask: {
        components: {
            Button: {
                colorText: "#4D4D4D",
                paddingBlock: "20px",
                fontWeight: 500,
                contentFontSize: 16,
                defaultBorderColor: "white",
                textHoverBg: "white",
            }
        }
    },
}
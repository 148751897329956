import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../../Layouts/Science/ScienceLayout";
import axios from "axios";
import {BusinessBlock} from "../../../Components/Science/BusinessBlock";

const ScienceCompetitions = () => {
    const {lang} = useSelector(state => state.lang);
    const [news, setNews] = useState([]);
    const [load, setLoad] = useState(false);

    const loadInfo = async () => {
        try {
            const res = await axios.get("https://bricsyoung.com/api/busyness/");
            setNews(res.data);
            console.log(res.data);
            setLoad(true);
        } catch (error) {
        }
    };

    useEffect(() => {
        loadInfo();
    }, []);

    const response = Object.values(news)
        .map((item) => ({
            company: item.company,
            companyen: item.companyen,
            description: item.description,
            descriptionen: item.descriptionen,
            vacancy: item.vacancy,
            vacancyen: item.vacancyen,
            country: item.country,
            countryen: item.countryen,
            contacts: item.contacts,
            contactsen: item.contactsen,
            university_ref: item.university_ref,
            images: item.images,
            id: item.id,
        }))

    return (
        <ScienceLayout title={lang === 'ru' ? 'Конкурсы и гранты' : 'Competitions and grants'} lang={lang}>
            <BusinessBlock response={response} load={load}/>
        </ScienceLayout>
    )
};

export default ScienceCompetitions;
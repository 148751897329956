import React, { useEffect, useState } from "react";
import styles from "../../Pages/Forum/Forum.module.css";
import { Collapse, ConfigProvider } from "antd";
import { MainThemes } from "../../themes/MainThemes";
import axios from "axios";
import { useSelector } from "react-redux";

const Thread = ({ thread }) => {
  const [questions, setQuestions] = useState([]);
  const [items, setItems] = useState([]);
  const [enItems, setEnItems] = useState([]);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`https://bricsyoung.com/api/forum?category=${thread}`)
        .then((resp) => {
          const allQ = resp.data.filter((el) => el.published !== "нет");
          setQuestions(allQ);
        });
    };
    fetchData();
  }, [thread, setQuestions]);

  useEffect(() => {
    if (questions) {
      setItems([]);
      setEnItems([]);
      for (let i = 0; i < questions.length; i++) {
        let newKey = {
          key: i + 1,
          label: questions[i]?.question,
          children: <div className={styles.answer}>{questions[i]?.answer}</div>,
        };
        let newEnKey = {
          key: i + 1,
          label: questions[i]?.questionen,
          children: (
            <div className={styles.answer}>{questions[i]?.answeren}</div>
          ),
        };
        setItems((items) => [...items, newKey]);
        setEnItems((enItems) => [...enItems, newEnKey]);
      }
    }
  }, [questions, setItems]);

  return (
    <ConfigProvider theme={MainThemes.forum}>
      <div className={styles.thread}>
        {items && (
          <Collapse
            style={{ marginTop: "50px" }}
            expandIconPosition="end"
            items={lang === "ru" ? items : enItems}
          />
        )}
      </div>
    </ConfigProvider>
  );
};

export default Thread;

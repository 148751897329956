import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {BlockTitleWithUrl} from "../News/SingleNewsPage";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {AnotherNewsLayout} from "../../Layouts/AnotherNews/AnotherNewsLayout";
import {AnotherNewsDisplay} from "../../Components/AnotherNews";

const AnotherNewsPage = () => {
    const {lang} = useSelector((state) => state.lang);
    const isSmall = useMediaQuery({query: "(max-width: 768px)"});
    const [respData, setData] = useState("");
    const [load, setLoad] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get("https://bricsyoung.com/api/news?category=наука")
                .then((res) => {
                    setData(res.data);
                })
                .catch((e) => {
                });
        };
        fetchData().finally(() => setLoad(true));
    }, []);

    const response = Object.values(respData)
        .map(function (item) {
            return {
                body: item.body,
                bodyen: item.bodyen,
                date: item.date,
                description: item.description,
                descriptionen: item.descriptionen,
                header: item.header,
                headeren: item.headeren,
                id: item.id,
                category: item.category,
                img: "https://bricsyoung.com" + item.img,
            };
        })
        .filter((el) => {
            let today = new Date();
            let second = new Date(el?.date);
            return second.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
        })

    return (
        <AnotherNewsLayout title={lang === "ru" ? "Наука" : "Science"}>
            <>
                <div>
                    <BlockTitleWithUrl
                        size={isSmall ? "16px" : "28px"}
                        ruTitle="Новости науки"
                        enTitle="Science News"
                        url="/news"
                    />
                    <AnotherNewsDisplay response={response} load={load} lang={lang}/>
                </div>
            </>
        </AnotherNewsLayout>
    );
};

export default AnotherNewsPage;

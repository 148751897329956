import React, { useState, useEffect } from "react";
import styles from "./Forum.module.css";
import Thread from "../../Components/Forum/Thread";
import { Button, ConfigProvider, Menu, Modal } from "antd";
import { MainThemes } from "../../themes/MainThemes";
import { useSelector } from "react-redux";
import logo1 from "../../assets/Header/LoginLogo.svg";
import AskModal from "../../Components/Forum/AskModal";

const ChatRoom = () => {
  const [current, setCurrent] = useState("наука");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const items = [
    {
      key: "наука",
      label: lang === "ru" ? "Наука" : "Science",
    },
    {
      key: "молодежь",
      label: lang === "ru" ? "Молодежь" : "Youth",
    },
    {
      key: "мероприятия",
      label: lang === "ru" ? "Мероприятия" : "Events",
    },
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const onCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ConfigProvider theme={MainThemes.forum}>
      <div className={styles.forum}>
        <h1>Вопрос - ответ</h1>
        <div className={styles.nav}>
          <div className={styles.left}>
            <Menu
              style={{ justifyContent: "left" }}
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
          </div>
          <div className={styles.right}>
            <Button onClick={showModal}>
              {lang === "ru" ? "Задать вопрос" : "Ask A Question"}
            </Button>
          </div>
          <Modal
            footer={() => <></>}
            title={<img src={logo1} alt="" />}
            open={isModalOpen}
            onCancel={onCancel}
          >
            <AskModal onCancel={onCancel} setIsModalOpen={setIsModalOpen} />
          </Modal>
        </div>

        <Thread thread={current} />
      </div>
    </ConfigProvider>
  );
};

export default ChatRoom;

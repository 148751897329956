import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";
import { ScienceLayout } from "../../Layouts/Science/ScienceLayout";
import { BlockTitleWithUrl } from "../News/SingleNewsPage";
import { NewsDisplay } from "../../Components/Main/NewsBlock";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { EventsDisplay } from "../../Components/Main/EventsBlock";

const Science = () => {
  const { lang } = useSelector((state) => state.lang);
  const isSmall = useMediaQuery({ query: "(max-width: 768px)" });
  const [respData, setData] = useState("");
  const [respData2, setData2] = useState("");
  const [load, setLoad] = useState(false);
  const [load2, setLoad2] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://bricsyoung.com/api/news?category=наука")
        .then((res) => {
          setData(res.data);
        })
        .catch((e) => {});
    };
    fetchData().finally(() => setLoad(true));
  }, []);

  const response = Object.values(respData)
    .map(function (item) {
      return {
        body: item.body,
        bodyen: item.bodyen,
        date: item.date,
        description: item.description,
        descriptionen: item.descriptionen,
        header: item.header,
        headeren: item.headeren,
        id: item.id,
        category: item.category,
        img: "https://bricsyoung.com" + item.img,
      };
    })
    .filter((el) => {
      let today = new Date();
      let second = new Date(el?.date);
      return second.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
    })
    .slice(0, 4);

  useEffect(() => {
    const fetchData2 = async () => {
      await axios
        .get("https://bricsyoung.com/api/science")
        .then((res) => {
          setData2(res.data);
        })
        .catch((e) => {});
    };
    fetchData2().finally(() => setLoad2(true));
  }, []);

  const response2 = Object.values(respData2)
    .map(function (item) {
      return {
        body: item.body,
        bodyen: item.bodyen,
        date: item.date || "",
        place: "",
        placeen: "",
        description: item.description,
        descriptionen: item.descriptionen,
        header: item.header,
        headeren: item.headeren,
        id: item.id || "",
        category: item.category,
        img: item.img,
      };
    })
    .slice(0, 4);

  return (
    <ScienceLayout title={lang === "ru" ? "Наука" : "Science"}>
      <>
      <Helmet>
          <title>{lang === "ru" ? "Наука – BRICS Mediaplatform" : "Science – BRICS Mediaplatform"}</title>
          <meta
            name="description"
            content={
              lang === "ru"
                ? "Откройте раздел науки на BRICS Mediaplatform: международные образовательные программы, исследования и гранты для молодежи из стран БРИКС."
                : "Explore the Science section on BRICS Mediaplatform: international education programs, research opportunities, and grants for youth in BRICS countries."
            }
          />
          <meta
            name="keywords"
            content={
              lang === "ru"
                ? "наука БРИКС, международные исследования, образование за рубежом, научные гранты, сотрудничество БРИКС, исследования молодежи, академическое развитие"
                : "BRICS science, international research, study abroad, scientific grants, BRICS cooperation, youth research, academic growth"
            }
          />
          <meta name="author" content="BRICS Mediaplatform" />
          <link rel="canonical" href="https://bricsyoung.com/science" />
        </Helmet>
        <div>
          <BlockTitleWithUrl
            size={isSmall ? "16px" : "28px"}
            ruTitle="Новости науки"
            enTitle="Science News"
            url="/news"
          />
          <NewsDisplay response={response} load={load} lang={lang} />
        </div>
        <p></p>
        <div>
          <BlockTitleWithUrl
            size={isSmall ? "16px" : "28px"}
            ruTitle="Анонсы"
            enTitle="Announcements"
            url="/announcements"
          />
          <EventsDisplay response={response2} load={load2} lang={lang} />
        </div>
        <p></p>
        <div style={{ paddingTop: "30px", fontSize: "18px" }}>
          <p>
            Добро пожаловать в <b>«Наука»</b> — раздел платформы <b>BRICS+</b>,
            где молодёжь из стран БРИКС может открыть для себя уникальные
            возможности в области международного образования, научных
            исследований и профессионального сотрудничества.
          </p>
          <p>
            Этот раздел ориентирован на облегчение поступления на международные
            программы, содействие академическому росту и объединение молодых
            исследователей для решения актуальных научных задач. Здесь
            пользователи смогут получить актуальную информацию о грантах и
            образовательных программах, познакомиться с историей успеха коллег,
            а также поделиться собственным опытом.
          </p>
          <p>
            <b>«Наука»</b> — это пространство для расширения академического и
            профессионального потенциала. На нашем сайте собраны ключевые
            сведения о международных программах, которые делают образование за
            рубежом доступнее. Студенты, аспиранты и молодые исследователи
            смогут узнать о требованиях и специфике образовательных систем стран
            БРИКС, а также найти полезные советы и материалы, чтобы процесс
            поступления стал проще и прозрачнее.
          </p>
          <p>
            Особое внимание в разделе уделено реальным историям выпускников и
            молодых учёных. Эти рассказы из первых уст раскрывают, как
            международные образовательные программы и совместные исследования
            формируют жизненный и профессиональный путь, вдохновляют на
            преодоление трудностей и достижение поставленных целей. Истории
            успеха и реальные кейсы из научной жизни помогут читателям лучше
            понять, какие перспективы открываются перед ними и какие шаги
            необходимо предпринять для собственного успеха.
          </p>
          <p>
            Сообщество учёных и исследователей платформы BRICS+ создано для тех,
            кто ищет партнёров для выполнения научных работ и грантовых
            проектов. Пользователи смогут найти коллег по общим интересам,
            развивать совместные проекты и формировать академические контакты,
            не ограниченные рамками одной страны.
          </p>
          <p>
            Для тех, кто хочет поделиться собственным опытом, раздел предлагает
            возможность опубликовать историю учёбы, участвовать в конкурсах на
            публикации и знакомить других с личным путём в науке. Эти публикации
            позволяют создать ценную базу знаний и вдохновения для будущих
            поколений студентов.
          </p>
          <p>
            Помимо образовательных программ и историй, «Наука» предлагает
            пользователям доступ к грантам, конференциям и различным
            мероприятиям, способствующим развитию знаний и построению сети
            полезных контактов.
          </p>
          <p>
            <b>Регистрация</b> на платформе BRICS+ откроет вам доступ ко всем функциям
            раздела, в том числе к эксклюзивным материалам, личному кабинету для
            учёных, возможностям публикации и обмена опытом с другими студентами
            и исследователями.
          </p>
          <p>
            <b>Откройте для себя новый уровень академического взаимодействия на
            международной платформе BRICS+ и станьте частью сообщества будущих
            лидеров науки.</b>
          </p>
        </div>
      </>
    </ScienceLayout>
  );
};

export default Science;
